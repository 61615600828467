export default {
  // 공통코드 데이터 설정
  getCommCodeData(state) {
    return state.commCodeData;
  },
  // 메인 레이어 팝업 선택 데이터
  getSelectedCodeData(state) {
    return state.selected;
  },
  // 건물 기본 팝업 건축물 대장
  getBuildBasicData(state) {
    return state.buildBasic;
  },
  // 건물 기본 팝업 건축물 대장
  getBuildFloorData(state) {
    return state.buildFloor;
  },
  // 에너지 사용량
  getEnergyUseData(state) {
    return state.energyUse;
  },
  // 에너지 사용량 총괄표제부 차트
  getEnergyChartData(state) {
    // console.log(`getEnergyChartData : ${JSON.stringify(state.energyChartTotal)}`)
    return state.energyChartTotal;
  },
  getSearchData(state) {
    return state.searchItem.map(v => ({
      bldNm: v.bld_nm,
    }));
  },
  // 시도 데이터 설정
  getSidoList(state) {
    return state.sidoList;
  },
  // 시군구 데이터 설정
  getSigunguList(state) {
    return state.sigunguList;
  },
  // 시군구 클릭
  getMapAreaData(state) {
    return state.mapAreaData;
  },
  // true: 에너지 사용량 건물, false: 시군구,읍면동
  getEngyUseChange(state) {
    return state.engyUseChange;
  },
  // 주용도 검색 선택값
  getSelectPurps(state) {
    return state.selectPurps;
  }
}
