export default {
  // overlay flag
  showOverlay: false,

  // 조회시 결과 성공 여부
  result: {
    resultFlag: true,
    resultMsg: ''
  },

  // 화면에 필요한 공통코드 리스트
  commCodeData: {},
  filterCommCodeData: {},

  // 시도, 시군구 데이터
  sidoList: [],
  sigunguList: [],

  // 선택된 필터 정보 - 저장용
  saveFilterInfo: {
    FILTER_NM: "",
    ANUAL_ST_CD: [],
    REGSTR_GB_CD: [],
    KMA_AREA_CD: [],
    SIDO_ID: [],
    SIGUNGU_ID: [],
    MAIN_PURPS_CD: [],
    INSLT_ST_CD: [],
    TOTAREA_CD: [],
    USE_YY_CD: [],
    CLSF_KIND_CD: [],
    ENGY_KIND_CD: [],
    OPEN_SVC_ID: [],
    OPEN_SVC_NAME: [],
    PLAT_OCP_CD: [], // 대지 점유 방식
    OWNER_TYPE_CD: [], // 소비자 유형
    PURPS_GB_CD: [], // 용도구분
    DTLD_USE_CD: [], // 세부 용도
    TYPCL_USE_CD: [], // 대표 용도
    UNF_USE_FLR: [], // 층별용도 통일성
    ANUAL_OPDYS_CD: [], // 연간 운영 일수
    AVRG_OPHRS_CD: [], // 일평균 운영시간
  },

  // 필터 정보 가져오기 - 필터 조회용
  filterInfo: [],
  filterFetchedInfo: {
    platOcpCd: [], // 대지 점유 방식
    regstrGbCd: [], // 소유 방식
    ownerTypeCd: [], // 소비자 유형
    kmaAreaCd: [],
    sidoId: [],
    sigunguId: [],
    purpsGbCd: [],
    mainPurpsCd: [],
    typclUseCd: [],
    dtldUseCd: [],
    unfUseFlr: [],
    insltStCd: [],
    anualStCd: [],
    totareaCd: [],
    anualOpdysCd: [],
    avrgOphrsCd: [],
    useYyCd: [],
    engyKindCd: [],
    clsfKindCd: [],
    evlpAreaTimeCd: [],
    evlpInfoCd: [],
    rgtDt: [],
    filterNm: "",
    openSvcId: [],
    openSvcName: [],
    engyAllQtyCd: [],
    totareaGbCd: [],

  },

  // 업종 정보
  opnsvcList: [],

  // 조회한 업종
  opnsvcLoadList: [],

  // 필터로 조회한 통계 정보
  data: {},

  // 요약정보
  dataSummary: {},

  // 차트 데이터
  boxplotData: {},
};
