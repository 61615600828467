// 저장할 필터 정보 초기화
const initSaveFilterInfo = () => ({
  FILTER_NM: "",
  ANUAL_ST_CD: [],
  REGSTR_GB_CD: [],
  KMA_AREA_CD: [],
  SIDO_ID: [],
  SIGUNGU_ID: [],
  MAIN_PURPS_CD: [],
  INSLT_ST_CD: [],
  TOTAREA_CD: [],
  USE_YY_CD: [],
  CLSF_KIND_CD: [],
  ENGY_KIND_CD: [],
  OPEN_SVC_ID: [],
  OPEN_SVC_NM: []
});

const initFilterFetchedInfo = () => ({
  platOcpCd: [],
  regstrGbCd: [],
  ownerTypeCd: [],
  kmaAreaCd: [],
  sidoId: [],
  sigunguId: [],
  purpsGbCd: [],
  mainPurpsCd: [],
  typclUseCd: [],
  dtldUseCd: [],
  unfUseFlr: [],
  insltStCd: [],
  anualStCd: [],
  totareaCd: [],
  anualOpdysCd: [],
  avrgOphrsCd: [],
  useYyCd: [],
  engyKindCd: [],
  clsfKindCd: [],
  evlpAreaTimeCd: [],
  evlpInfoCd: [],
  rgtDt: [],
  filterNm: [],
  openSvcId: [],
  openSvcName: [],
  engyAllQtyCd: [],
  totareaGbCd: [],
});

// state에 선언된 변수에 값을 할당함.
export default {
  // overlay flag 설정
  SET_SHOW_OVERLAY(state, payload) {
    state.showOverlay = payload;
  },
  // 결과 성공 여부
  SET_RESULT(state, payload) {
    state.result = payload
  },
  // 공통코드 데이터 설정
  SET_COMM_CODE_DATA(state, payload) {
    state.commCodeData = payload;
  },
  // 필터 공통
  SET_FILTER_COMM_CODE_DATA(state, payload) {
    state.filterCommCodeData = payload;
  },
  // 시도 데이터 설정
  SET_SIDO_LIST(state, payload) {
    state.sidoList = payload;
  },
  // 시군구 데이터 설정
  SET_SIGUNGU_LIST(state, payload) {
    state.sigunguList = payload;
  },

  // 저장할 필터 정보 초기화
  SET_RESET_SAVE_FILTER_INFO(state) {
    state.saveFilterInfo = initSaveFilterInfo();
  },
  // 필터 정보 설정
  SET_FILTER_INFO(state, payload) {
    state.filterInfo = payload;
  },

  // 조회한 필터 정보 설정
  SET_FILTER_FETCHED_INFO(state, payload) {
    state.filterFetchedInfo = payload;
  },

  // 조회한 필터 정보 reset
  SET_RESET_FILTER_FETCHED_INFO(state) {
    state.filterFetchedInfo = initFilterFetchedInfo();
  },

  // 조회한 필터 정보 설정
  SET_INIT_FILTER_FETCHED_INFO(state, payload) {
    state.filterFetchedInfo[payload.key] = payload.value;
  },

  // 집단별 통계 요약 추가
  SET_FILTER_FETCHED_INFO_ADD_CONDITION(state) {
    state.filterFetchedInfo.engyAllQtyCd = []; // 총량
    state.filterFetchedInfo.totareaGbCd = []; // 규모 정규화
  },

  // 필터 통계 데이터 설정
  SET_DATA(state, payload) {
    state.data = payload;
  },
  // 필터 요약 데이터 설정
  SET_DATA_SUMMARY(state, payload) {
    state.dataSummary = payload;
  },

  // 필터 선택된 데이터 설정
  // payload = {key: 키, value: 값}
  SET_INIT_SELECTED_COMM_CODE(state, payload) {
    state.saveFilterInfo[payload.key] = payload.value;
  },
  // 필터 선택된 데이터 설정 - 시도
  // payload = {key: 키, value: 값}
  SET_INIT_SELECTED_ADDR(state, payload) {
    state.saveFilterInfo[payload.key] = payload.value;
  },

  // 업종 정보 데이터 설정
  SET_OPNSVC_LIST(state, payload) {
    state.opnsvcList = payload;
  },

  // 박스플롯 데이터 설정
  SET_BOXPLOT_DATA(state, payload) {
    state.boxplotData = payload;
  },

  SET_OPNSVC_LOAD_DATA(state, payload) {
    state.opnsvcLoadList = payload;
  },
};
