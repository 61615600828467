export default {

  // 화면에 필요한 공통코드 리스트
  commCodeData: {},

  // 시도, 시군구 데이터
  sidoList: [],
  sigunguList: [],

  // 메인 레이어 팝업
  selected: {
    useYyCd: { cdNm: '2017년', cdId: '2017' },
    useYyLasooCd: { cdNm: '2017년', cdId: '2017' },
    engyKindCd: { cdNm: '전체', cdId: 'All' },
    clsfKindCd: { cdNm: '전체', cdId: 'All' },
    engyAllQtyCd: { cdNm: '온실가스 배출량', cdId: '03' },
    engyUse: { cdNm: '건물', cdId: 'build' },
    engyUseCd: [{ cdNm: '시군구', cdId: 'sigungu' }, { cdNm: '읍면동', cdId: 'bjdong' }, { cdNm: '건물', cdId: 'build' }],
    MAIN_PURPS_CD: [],
    SIDO: [],
    SIGUNGU: [],
    ENGY_ALL_QTY_CD: [],
  },

  mapAreaData: {}, // 시군구, 읍면동 데이터 리스트
  buildBasic: {}, // 건물 기본 정보
  buildFloor: [], // 층별 개요
  energyUse: {}, // 에너지 사용랑
  energyChartTotal: [], // 에너지 차트
  searchItem: {}, // 메인 검색 리스트
  licenseList: [], // 인허가 리스트
  meterList: [], // 계량기정보
  buildLassoStmt: [], // 올가미 선택한 용도별 통계 요약
  buildLassoChoice: [], // 올가미 선택한 건물정보
  // selectPurps: { cdNm: '전체', cdId: 'All' }, // 선택된 주용도
  engyUseChange: false, // true: 에너지 사용량 건물, false: 시군구,읍면동

  tempBuildInfo: {}, // 지도에서 선택한 데이터 정보
  totareaDstrb: {}, // Y일경우 총괄표제부의 에너지 소비량을 건물의 연면적으로 배분한 값
};
