import axios from "axios";

// HTTP reqeust & response와 관련된 기본 설정을 해줌
const config = {
  url_commCode: "/api/commCode/",
  url_main: "/api/main/"
};

const mainAxiosCall = {
  /** *********** 공통 함수 영역  ***********  */
  // 공통코드 정보 호출
  fetchCommCodeData() {
    const commCodeParam = [
      { columnId: "USE_YY_CD" }, // 년도
      { columnId: "ENGY_KIND_CD" }, // 에너지원
      { columnId: "CLSF_KIND_CD" }, // 사용용도
      { columnId: "ENGY_ALL_QTY_CD" }, // 건물소비량
      { columnId: "MAIN_PURPS_CD" }, // 주용도
      { columnId: "SIDO" },
      { columnId: "SIGUNGU" },
    ];
    return axios.post(`${config.url_commCode}getCommCodeList`, commCodeParam);
  },
  // 시도 정보 호출
  fetchSidoList() {
    return axios.post(`${config.url_commCode}getSidoList`);
  },
  // 시군구 정보 호출
  fetchSigunguList(sidoId) {
    return axios.post(`${config.url_commCode}getSigunguList`, { sidoId });
  },

  /** *********** 지도 함수 영역  ***********  */
  // 지도 인허가 데이터 조회 ex) 스타벅스
  fetchSearchAuthData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
  // 건물 기본 정보 데이터 조회
  fetchBuildBasicData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
  // 건물 기본 정보 층별 개요
  fetchBuildFloorData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
  // 지도의 영역 클릭 ex) 강남구, 역삼동..
  fetchMapAreaData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
  // 인허가 데이터 정보
  fetchBuildLicenseData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
  // 에너지 사용량
  fetchEnergyUseData(payload) {
    return axios.post(`${config.url_main}getEnergyUseData`, { payload });
  },
  // 차트 데이터
  fetchEnergyChartData(payload) {
    return axios.post(`${config.url_main}getEnergyChartData`, { payload });
  },
  // 계량기 정보
  fetchEnergyMeterInfoData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
  // 올가미 선택한 용도별 통계 요약
  fetchBuildLassoStmtData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
  //  올가미 건물 선택 리스트
  fetchBuildLassoChoiceData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
  // 임시 저장된 데이터로 건물정보 로드
  fetchTempBuildInfoData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
  fetchTotareaDstrbData(payload) {
    return axios.post(`${config.url_main}getBuildDataInfo`, { payload });
  },
}
// eslint-disable-next-line import/prefer-default-export
export { mainAxiosCall };
