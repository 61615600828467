import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import notice from "./notice";
import filter from "./filter";
import main from "./main";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    notice,
    filter,
    main,
  },
  plugins: [
    createPersistedState({
      // 주목! : 여기에 쓴 모듈만 저장됩니다.
      paths: ["cart", "auth"],
    }),
  ],
  strict: process.env.DEV,
});
