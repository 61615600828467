import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush
    .call(this, location)
    .catch(() => window.location.reload());
};

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: "/energy",
    },
    {
      path: "/common-layer",
      name: "common-layer",
      component: () => import("@/views/commonLayer/IndexSampleView.vue"),
    },
    {
      path: "/energy",
      name: "energy",
      component: () => import("@/views/building/BuildingService.vue"),
      meta: {
        layout: 'full'
      }
    },
    {
      path: "/filter-service",
      name: "filter-service",
      component: () => import("@/views/filter/FilterMain.vue"),
    },
    {
      path: "/filter-service-checkbox",
      name: "filter-service-checkbox",
      component: () => import("@/views/filter/FilterMainRenew.vue"),
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
