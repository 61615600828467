import { mainAxiosCall } from "@api/main";
import { utils } from "@/devUtils/commUtils";
import { filterAxiosCall } from "@api/filter";

export default {
  /** *********** 공통 함수 영역  ***********  */
  FETCH_COMM_CODE_DATA({ commit }) {
    mainAxiosCall.fetchCommCodeData().then((res) => {
      if (res.data.resultFlag) {
        commit("SET_COMM_CODE_DATA", res.data.resultData);

        const defaultKeyInfoList = utils.fnFindDefaultValue4Map(
          res.data.resultData,
          "defValYn"
        );
        if (defaultKeyInfoList !== undefined && defaultKeyInfoList !== null) {
          Object.keys(defaultKeyInfoList).forEach((element) => {
            // default값 설정
            commit("SET_INIT_SELECTED_COMM_CODE", {
              key: element,
              value: defaultKeyInfoList[element],
            });
          });
        }
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 시도 데이터 조회
  FETCH_SIDO_LIST({ commit, dispatch, state }) {
    mainAxiosCall
      .fetchSidoList()
      .then((res) => {
        if (res.data.resultFlag) {
          commit("SET_SIDO_LIST", res.data.resultData);

          // default값 설정
          const defaultSido = utils.fnFindDefaultValue4Array(
            res.data.resultData,
            "defValYn"
          );

          if (defaultSido !== undefined && defaultSido !== null) {
            // defaultValue가 설정되어 있는 경우
            commit("SET_INIT_SELECTED_COMM_CODE", {
              key: "SIDO",
              value: defaultSido,
            });
            // 시군구 데이터 조회
            const sidoId = state.selected.SIDO[0].sidoId;
            dispatch("FETCH_SIGUNGU_LIST", sidoId);
          }
        } else {
          alert(res.data.resultMsg);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  // 시군구 데이터 조회
  FETCH_SIGUNGU_LIST({ commit }, sidoId) {
    // 선택된 시군구 정보 초기화
    commit("SET_INIT_SELECTED_ADDR", { key: "SIGUNGU", value: [] });

    if (sidoId !== null) {
      mainAxiosCall
        .fetchSigunguList(sidoId)
        .then((res) => {
          if (res.data.resultFlag) {
            // 시군구 데이터 설정
            commit("SET_SIGUNGU_LIST", res.data.resultData);
          } else {
            alert(res.data.resultMsg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      commit("SET_SIGUNGU_LIST", []);
    }
  },

  /** *********** 지도 함수 영역  ***********  */
  // 인허가 업종 검색 ex)스타벅스
  async FETCH_SEARCH_AUTH_DATA({ commit }, payload) {
    await mainAxiosCall.fetchSearchAuthData(JSON.parse(JSON.stringify(payload)))
      .then((res) => {
        console.log("res.dataAuthSearch > ", res.data);
        if (res.data.resultFlag) {
          commit("SET_SEARCH_AUTH_DATA", res.data.resultData);
        } else {
          alert(res.data.resultMsg);
        }
      })
  },
  // 건물 기본 정보
  async FETCH_BUILD_BASIC_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildBasicData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_BUILD_BASIC_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 건물 층별 개요
  async FETCH_BUILD_FLOOR_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildFloorData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_BUILD_FLOOR_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
  // 지도 영역 데이터
  async FETCH_MAP_AREA_DATA({ commit }, payload) {
    await mainAxiosCall.fetchMapAreaData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_MAP_AREA_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 인허가 데이터 정보
  async FETCH_BUILD_LICENSE_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildLicenseData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_BUILD_LICENSE_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
  // 에너지 사용량
  FETCH_ENERGY_USE_DATA({ commit }, payload) {
    mainAxiosCall.fetchEnergyUseData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_ENERGY_USE_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
  // 에너지 종류별 에너지 사용량
  async FETCH_ENERGY_KIND_CHART_DATA({ commit }, payload) {
    await mainAxiosCall.fetchEnergyChartData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_ENERGY_KIND_CHART_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
  // 용도별 에너지 사용량
  async FETCH_CLSF_KIND_CHART_DATA({ commit }, payload) {
    await mainAxiosCall.fetchEnergyChartData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_CLSF_KIND_CHART_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
  // 계량기 정보
  async FETCH_ENERGY_METER_INFO_DATA({ commit }, payload) {
    await mainAxiosCall.fetchEnergyMeterInfoData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_ENERGY_METER_INFO_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
  // 올가미 선택한 용도별 통계 요약
  async FETCH_BUILD_LASSO_STMT_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildLassoStmtData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_BUILD_LASSO_STMT_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
  // 올가미 건물 선택 리스트
  async FETCH_BUILD_LASSO_CHOICE_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildLassoChoiceData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_BUILD_LASSO_CHOICE_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
  //   시군구, 읍면동는 주용도검색 비활성
  //    건물일 경우 주용도검색 활성
  FETCH_ENERGY_USE_CHANGE({ commit }, engyUseChange) {
    commit('SET_ENERGY_USE_CHANGE', engyUseChange);
  },
  // 주용도검색 선택값 state 저장
  FETCH_SELECT_PURPS_DATA({ commit }, mainPurpsCd) {
    commit('SET_SELECT_PURPS_DATA', mainPurpsCd);
  },
  // 임시 저장된 데이터로 건물정보 로드
  async FETCH_TEMP_BUILD_INFO_DATA({ commit }, payload) {
    await mainAxiosCall.fetchTempBuildInfoData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_TEMP_BUILD_INFO_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
  async FETCH_TOTAREA_DSTRB_DATA({ commit }, payload) {
    await mainAxiosCall.fetchTotareaDstrbData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit("SET_TOTAREA_DSTRB_DATA", res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    })
  },
};
